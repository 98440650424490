import React from 'react';
import PropTypes from 'prop-types';
import InfoCourseCard from '@components/ui/course-info-card-fixed';
import { isCourseClosedForSignUp, getPathToCoursePage } from '@utilities/course-data-helpers';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";
import styles from './closed-courses.module.scss';

const renderHeader = () => (
  <div className={styles.description}>
    <section className={styles.heading}>
      <h2>Cursos passados</h2>
    </section>
    <section className={styles.details}>
      <div>
        <p>
          Aqui poderás encontrar informação sobre alguns dos cursos
          After School que já realizámos.  
        </p>
      </div>
    </section>
  </div>
);

const ClosedCourses = (props) => {
  const breakpoints = useBreakpoint();
  const coursesWithClosedSignUp = props.allCoursesData.filter(isCourseClosedForSignUp);

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${breakpoints.mobile ? 158 : 255}px, 1fr))`,
    gridColumnGap: '29px',
    gridRowGap: '29px',
    justifyItems: breakpoints.mobile ? 'start' : 'center'
  };

  return (
    <div className={styles.container}>
      {renderHeader()}

      <div style={{ ...containerStyle }}>
        {
          [...coursesWithClosedSignUp].map(course => {
            const { course_iter_id, scholarity, course: {name: course_name}, image} = course;
            const path = getPathToCoursePage(course);

            return (
              <div key={course_iter_id}>
                <Link to={path}>
                    <InfoCourseCard
                      courseName={course_name}
                      scholarity={scholarity}
                      fluid={image !== null ? image.localFile.childImageSharp.fluid : null}
                      desktop={{
                        height: 255,
                        width: 255,
                        fontSize: { courseName: 20, scholarity: 12 },
                        margin: 40,
                      }}
                      mobile={{
                        height: 158,
                        width: 158,
                        fontSize: { courseName: 14, scholarity: 10 },
                        margin: 10,
                      }}
                    />
                </Link>
              </div>
            );
          })
        }
      </div>

    </div>
  );
};

export default ClosedCourses;

ClosedCourses.propTypes = {
  allCoursesData: PropTypes.arrayOf(PropTypes.object)
};